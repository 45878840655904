import React, {PropsWithChildren} from 'react';
import classes from './MailTo.module.css';

interface MailToProps extends PropsWithChildren<any> {
  email: string;
  children: React.ReactNode;
  headers: object;
  obfuscate?: boolean;
}

const toSearchString = (searchParams: object = {}) => {
  return (
    Object.keys(searchParams)
      // @ts-ignore
      .map((key) => `${key}=${encodeURIComponent(searchParams[key])}`)
      .join('&')
  );
};

const createMailLink = (email: string, headers: object) => {
  let link = `mailto:${email}`;
  if (headers) {
    link += `?${toSearchString(headers)}`;
  }
  return link;
};

const mailTo: React.FC<MailToProps> = (props: MailToProps) => {
  const {email, obfuscate, headers, children, ...others} = props;
  const handleLinkClick = (event: React.MouseEvent) => {
    event.preventDefault();
    window.location.href = createMailLink(email, headers);
  };

  const obfuscatedLink = (
    <a
      className={classes.Link}
      href="mailto:obfuscated"
      onClick={handleLinkClick}
      {...others}
    >
      {children}
    </a>
  );
  const link = (
    <a href={createMailLink(email, headers)} {...others}>
      {children}
    </a>
  );

  return obfuscate ? obfuscatedLink : link;
};

mailTo.defaultProps = {
  obfuscate: false,
};

export default mailTo;
