import React, {useEffect} from 'react';
import imageStub from './assets/images/uderConstraction.png';
import classes from './App.module.scss';
import MailTo from './components/MailTo/MailTo';
import logoImg from './assets/svg/logo.svg';

let isDev = true;

if (process.env.NODE_ENV === 'production') {
  isDev = false;
  // require('./analytics');
}

function App() {
  const email = 'office@povitec.com';
  const companyName = 'Povitec Limited';

  useEffect(() => {
    if (!isDev) {
      // @ts-ignore
      window.gtag('config', 'G-LSGENN03XV', {
        page_title: document.title,
        page_path: window.location.pathname + window.location.search,
      });
    }
  }, []);
  return (
    <div className={classes.App}>
      <header className={classes.AppHeader}>
        <img src={logoImg} width="60%"/>
      </header>
      <div className={classes.FlexWrapper}>
        <div className={classes.MailtoContainer}>
          <MailTo headers={{}} email={email} obfuscate={true}>Write Us</MailTo>
        </div>
      </div>
      <div>
        <img
          src={imageStub}
          className={classes.AppUnderConstruction}
          alt="Under Construction" width='50%'
        />
      </div>
    </div>
  );
}

export default App;
